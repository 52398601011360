import { AgGridModule } from '@ag-grid-community/angular';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgIconsModule } from '@ng-icons/core';
import { simpleKubernetes } from '@ng-icons/simple-icons';
import { SidepanelComponent } from '../../archive/shared/sidepanel/sidepanel.component';
import { AdmonitionComponent } from './admonition-box/admonition.component';
import { AgBtnRendererComponent } from './ag-btn-renderer/ag-btn-renderer.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CancomTableComponent } from './cancom-table/cancom-table.component';
import { CancomTableLoadingOverlay } from './cancom-table/loading-overlay/cancom-table-loading.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box.component';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { DialogsHelpboxLayoutComponent } from './dialogs-helpbox-layout/dialogs-helpbox-layout.component';
import { AssumableRoleWildcardDialogComponent } from './dialogs/assumable-role-wildcard-dialog/assumable-role-wildcard-dialog.component';
import { BooleditorDialogComponent } from './dialogs/booleditor-dialog/booleditor-dialog.component';
import { CreateSessionFeedbackComponent } from './dialogs/create-session-feedback/create-session-feedback.component';
import { KeyValueEditorDialogComponent } from './dialogs/keyvalue-editor-dialog/keyvalue-editor-dialog.component';
import { MultiTenantSelectorDialogComponent } from './dialogs/multi-tenant-selector-dialog/multi-tenant-selector-dialog.component';
import { OverwriteBackendEndpointDialogComponent } from './dialogs/overwrite-backend-endpoint-dialog/overwrite-backend-endpoint-dialog.component';
import { ReassumableRoleWildcardDialogComponent } from './dialogs/reassumable-role-wildcard-dialog/reassumable-role-wildcard-dialog.component';
import { SelectDialogComponent } from './dialogs/select-dialog/select-dialog.component';
import { StayOnPageDialogComponent } from './dialogs/stay-on-page-dialog/stay-on-page-dialog.component';
import { TextInputeditorDialogComponent } from './dialogs/textInputeditor-dialog/textInputeditor-dialog.component';
import { TexteditorDialogComponent } from './dialogs/texteditor-dialog/texteditor-dialog.component';
import { CancomDatePipe } from './formatted-date-pipe/formatted-date.pipe';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { SkeletonDirective } from './loading-skeleton/skeleton.directive';
import { MonthpickerComponent } from './monthpicker/monthpicker.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { OnetimeViewerComponent } from './onetime-viewer/onetime-viewer.component';
import { SchemaBasedEditorComponent } from './schema-based-editor/schema-based-editor.component';
import { DefaultServiceLayoutComponent } from './template/default-service-layout/default-service-layout.component';
import { TypeAssertPipe } from './type-assert/type-assert.pipe';

@NgModule({
	declarations: [
		AdmonitionComponent,
		AgBtnRendererComponent,
		AssumableRoleWildcardDialogComponent,
		BackButtonComponent,
		BooleditorDialogComponent,
		BreadcrumbComponent,
		CancomDatePipe,
		CancomTableComponent,
		CancomTableLoadingOverlay,
		ComingSoonComponent,
		ConfirmationBoxComponent,
		CreateSessionFeedbackComponent,
		CustomerSelectorComponent,
		DefaultServiceLayoutComponent,
		DialogsHelpboxLayoutComponent,
		KeyValueEditorDialogComponent,
		LandingPageComponent,
		LoadingOverlayComponent,
		MonthpickerComponent,
		MultiTenantSelectorDialogComponent,
		NotificationBarComponent,
		OnetimeViewerComponent,
		OverwriteBackendEndpointDialogComponent,
		ReassumableRoleWildcardDialogComponent,
		SchemaBasedEditorComponent,
		SelectDialogComponent,
		SidepanelComponent,
		SkeletonDirective,
		StayOnPageDialogComponent,
		TexteditorDialogComponent,
		TextInputeditorDialogComponent,
		TypeAssertPipe,
	],
	imports: [
		AgGridModule,
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatTableModule,
		MatTreeModule,
		PortalModule,
		ReactiveFormsModule,
		RouterModule,
		ScrollingModule,
		NgIconsModule.withIcons({ simpleKubernetes }),
	],
	exports: [
		AdmonitionComponent,
		BackButtonComponent,
		BooleditorDialogComponent,
		BreadcrumbComponent,
		CancomDatePipe,
		CancomTableComponent,
		CommonModule,
		CustomerSelectorComponent,
		DefaultServiceLayoutComponent,
		DialogsHelpboxLayoutComponent,
		LandingPageComponent,
		MonthpickerComponent,
		MultiTenantSelectorDialogComponent,
		SchemaBasedEditorComponent,
		SelectDialogComponent,
		SidepanelComponent,
		SkeletonDirective,
		StayOnPageDialogComponent,
		TexteditorDialogComponent,
		TextInputeditorDialogComponent,
		TypeAssertPipe,
	],
})
export class SharedComponentsModule {}
